export const API_URL = "https://api.secondstage.app"
export const TOOLPRESS_API = "https://toolpress-api.2ndstage.app/"

export const THEME_CONFIG = {
    mode:"dark",
    gradients: {
        default: ['#e4051f', '#e4051f'],
        home_question: ['#e4051f', '#e4051f'],
        applausometro: ['#E5634A', '#E5448A'],
        siNo:['#3a4de4', '#00d8a1'],
        sentiment: ['#3a4de4', '#00d8a1'],
        wordcloud: ['#EA4D95', '#E76E51'],
        qna: ['#EA4D95', '#E76E51'],
    },
    primary: '#e4051f',
    secondary: '#263238',
    accent: '#e4051f',
    text: '#eceff1',
    background:"#03156b",
    poll:{
        default:"#0a2bd8",
        answered:"#e4051f",
        correct: '#3A722D',
        wrong: '#e4051f'
    }
}