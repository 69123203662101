import React, {useEffect, useState} from "react";
import Applausometro from "./Applausometro";
import Question from "./Questions/Question";
import {useParams} from "react-router-dom";
import {firestore} from "../firebase/clientApp";
import {alpha, Box, CircularProgress, Grid, Stack, Typography} from "@mui/material";
import {useCollection, useDocument} from "react-firebase-hooks/firestore";
import {DisplayName} from "./DisplayName";
import {PinnedQuestions} from "./PinnedQuestions";
import pleshDark from "../theme/pleshDark";

const LogoBox = ({logo}) => {
    return(
        <Box style={{width:'100%', height:'30%'}} sx={{px: 2}}>
            <img style={{ margin:'auto', width:'80%', height:'100%', objectFit:'contain'}} src={logo}/>
        </Box>
    )
}

export default function VoteContent({user, event, loading, displayName, setDisplayName}) {
    const {id} = useParams()
    const [idPlaying, setIdPlaying] = useState('')
    const [eventPlaying, setEventPlaying] = useState('')
    const [pinned, pinnedLoading, pinnedError] = useCollection(firestore.collection('users/plesh/events/' + id + '/queue').where("pinned", "==",true), {})
    const url = 'users/plesh/events/'
    const [theme, themeLoading, themeError] = useDocument(firestore.collection('users/plesh/events/' + id + '/themes').doc(event?.data().theme))

    useEffect(() => {
        getIdPlaying()
    }, [])

    useEffect(() => {

    }, [displayName]);

    function getIdPlaying() {
        firestore.collection(url).doc(id).onSnapshot(s => {
            let e = s.data().elementPlaying
            let g = s.data().playing
            setIdPlaying(e)
            setEventPlaying(g)
        })
    }

    return (
        <Box>
            <Stack direction={"column"} justifyContent={"start"} alignContent={"center"} sx={{height: '75vh', py: 2}}
                   spacing={4}>
                <Box sx={{height: '4rem'}}></Box>
                {event.data().logo && <LogoBox
                    logo={event.data().logoUrl ? event.data().logoUrl : 'https://storage.googleapis.com/2ndstage-public/logos/logo-2nd.png'}/>}
                {
                    event.data().autenticazione &&
                    <DisplayName displayName={displayName} setDisplayName={setDisplayName}/>
                }
                <Stack pt={10} pb={3} sx={{width: '85%', margin: 'auto !important', paddingTop:"10rem", textAlign:'center'}}>
                    {/* <Typography fontWeight={'bold'} variant={'h6'} sx={{color: '#26358c'}}>AGENDA</Typography> */}
                    {/*<Box height={'1px'} sx={{background: 'white', margin: 'auto', mt: 1}} width={'70%'} />*/}
                    <Grid py={1} container
                          sx={{
                              margin: 'auto',
                              px:1,
                              background: alpha("#26358c", 1),
                              borderRadius: '2rem',
                              border:'1px solid white',
                              padding:'1rem'
                          }}>


                        {
                            agenda.map((event) => (<>
                                    <Grid item xs={4} sm={6} textAlign={'right'}>
                                        <Typography sx={{mr: 1}} fontWeight={'lighter'} fontSize={'small'}>
                                            {event.ore}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} sm={6}>
                                        <Typography sx={{ml: 1}} fontWeight={'bold'} textAlign={'left'} fontSize={'small'}>
                                            {event.evento}
                                        </Typography>
                                    </Grid>
                                </>
                            ))
                        }
                    </Grid>
                </Stack>
                {!pinnedLoading && !pinnedError && pinned ?
                    event.data().autenticazione
                        ?
                        (displayName !== '' && displayName) &&
                        <PinnedQuestions questions={pinned}/>
                        :
                        <PinnedQuestions questions={pinned}/>
                    :
                    <></>
                }
            </Stack>
            {loading ?
                <Box sx={{textAlign: 'center'}}>
                    <CircularProgress/>
                </Box>
                :
                event.data().applausometro ?
                    event.data().autenticazione
                    ?
                    (displayName !== '' && displayName) &&
                        <Applausometro url={url + id} idPlaying={idPlaying} eventPlaying={eventPlaying} user={user}/>
                    :
                    <Applausometro url={url + id} idPlaying={idPlaying} eventPlaying={eventPlaying} user={user}/>
                :
                    <></>
            }
            {(theme && !themeLoading && !themeError && !loading) ?
                event.data().autenticazione
                    ?
                    (displayName !== '' && displayName) &&
                        <Question event={event} idPlaying={idPlaying} url={url + id} user={user} theme={theme.data()}/>
                    :
                    <Question event={event} idPlaying={idPlaying} url={url + id} user={user} theme={theme.data()}/>
                :
                <></>
            }
        </Box>

    )
}

const agenda = [
    {ore: "15.30", evento: "Townhall Meeting"},
    {ore: "18:30", evento: "Aperitivo e cena"},
    {ore: "22:30", evento: "Termine evento"}
]
